import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState";

export default function changeCurrentSubCategoryListReducer(state=initialState.subCategories,action){
    switch (action.type) {
        case actionTypes.CHANGE_CURRENT_SUBCATEGORY_LIST:
            return action.payload
        default:
            return state;
    }
}