import React from "react";
import { useTranslation } from "react-i18next";

export function NotFound() {
  const {t} = useTranslation('common');
    
    return (
      <div>
      <div>
        <h3 className="centersuccess">{t("notfound.404")}</h3>
      </div>
      <div className="centersuccess">
        <img src="/images/404.png" alt="404" />
      </div>
      <hr></hr>
    </div>
    );
}
