import * as actionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default function getCategoriesReducer(
  state = initialState.categories,
  action
) {
  switch (action.type) {
    case actionTypes.SAVE_CATEGORY_SUCCESS:
      // const categoryList = state.filter(
      //   (item) => item._id !== action.payload._id
      // );
      // categoryList.push(action.payload);
      // return categoryList;
      state.push(action.payload);
      return state;
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return action.payload;

    case actionTypes.DELETE_CATEGORY_SUCCESS:
      const newState = state.filter((item) => item._id !== action.payload._id);
      return newState;
    default:
      return state;
  }
}
