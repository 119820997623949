import React, { Component } from "react";

import { login, logout } from "../../redux/actions/loginActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import  * as businessActions from "../../redux/actions/businessActions";
import  * as categoryActions from "../../redux/actions/categoryActions";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ChangeLanguage from "../commonui/ChangeLanguage";
import initialState from "../../redux/reducers/initialState";

class MyNavBar extends Component {
  state = { Collapse: false };

  toggleNavBar = () => {
    this.setState({ Collapse: !this.state.Collapse });
  };

  render() {
    const { t } = this.props;
    let isAdmin = false;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) isAdmin = userInfo.token ? true : false;

    return (
      <div>
        <Navbar light expand="md">
          <Link
            style={{
              padding: "0px",
              marginRight: "20px",
              marginBottom: "10px",
            }}
            to="/"
            className="navbarslogan slogan"
          >
            {" "}
            <img src={t("navbar.logopath")} width="55" height="55" alt="logo" />
            {t("navbar.slogan")}
          </Link>
          <NavbarToggler onClick={this.toggleNavBar} />
          <Collapse isOpen={this.state.Collapse} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem  style={{ marginRight: "10px", marginTop: "3px" }}>
                <Link onClick={()=>{this.toggleNavBar(); this.props.actions.changeBusinessList(initialState.businessList)}}
                  className="font-weight-bold navlink"
                  to={t("appurl.home")}
                >
                  {t("navbar.home")}
                </Link>
              </NavItem>

              <NavItem  style={{marginRight: "10px", marginTop: "3px" }}>
                <Link  onClick={()=>this.toggleNavBar()}
                  className="font-weight-bold navlink"
                  to={t("appurl.about")}
                >
                  {t("navbar.about")}
                </Link>
              </NavItem>

              <NavItem  style={{ marginRight: "10px", marginTop: "3px" }}>
                <Link 
                
                onClick={()=>this.toggleNavBar()}
                  className="font-weight-bold navlink"
                  to={t("appurl.contact")}
                >
                  {t("navbar.contact")}
                </Link>
              </NavItem>
              {isAdmin ? (
                <UncontrolledDropdown style={{marginRight:"10px", backgroundColor:"#FF5500"}} nav inNavbar>
                  <DropdownToggle nav caret>
                  {t("navbar.management")}
                  </DropdownToggle>
                  <DropdownMenu right style={{backgroundColor:"#B1CDF9"}}>
                    <DropdownItem  tag={Link} to="/categorylist">  {t("navbar.categorylist")}</DropdownItem>

                    <DropdownItem divider />


                    <DropdownItem tag={Link} to="/businesslist" onClick={()=>{ 
                      this.props.actions.changeCurrentCategory({});
                      this.props.actions.changeCurrentSubCategory({});
                      this.props.actions.changeBusinessList([]);
                      this.toggleNavBar();
                      }}>
                    {t("navbar.businesslist")}
                    </DropdownItem>
                    <DropdownItem divider />

                    <DropdownItem tag={Link} to="/categoryform/new">
                    {t("navbar.addcategory")}
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem tag={Link} to="/subcategoryform/new">
                    {t("navbar.addsubcategory")}
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem tag={Link} to="/businessform/new">
                    {t("navbar.addbusiness")}
                    </DropdownItem>
                    
                    <DropdownItem divider />
                    <DropdownItem tag={Link} to="/messagelist">
                    {t("navbar.showmessages")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                ""
              )}

              <NavItem>
                <Link onClick={()=>this.toggleNavBar()}
                  style={{ marginRight: "10px" }}
                  to={t("appurl.addyourbusiness")}
                >
                  <Button color="warning">{t("navbar.addyourbusiness")}</Button>
                </Link>
              </NavItem>

              <NavItem onClick={()=>this.toggleNavBar()} style={{ marginTop: "10px" }} >
                <ChangeLanguage  />
              </NavItem>
            </Nav>

            {isAdmin ? (
              <NavbarText>
                <Link
                  to="/login"
                  onClick={() => {
                    this.props.actions.logout();
                  }}
                >
                    {t("navbar.logout")}
                </Link>
              </NavbarText>
            ) : (
              ""
            )}
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.loginReducer };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(login, dispatch),
      logout: bindActionCreators(logout, dispatch),
      changeBusinessList: bindActionCreators(
        businessActions.getBusinessListSuccess,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategory: bindActionCreators(
        categoryActions.changeCurrentSubCategory,
        dispatch
      ),
    },
  };
}

export default withTranslation("common")(
  withRouter (connect(mapStateToProps, mapDispatchToProps)(MyNavBar))
);
