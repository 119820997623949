import * as actionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default function changeCurrentCategoryReducer(
  state = initialState.currentCategory,
  action
) {
  switch (action.type) {
    case actionTypes.CHANGE_CURRENT_CATEGORY:
      return action.payload;

    case actionTypes.DELETE_SUBCATEGORY_SUCCESS:
      const newSubCatList = state.subCategories.filter(
        (item) => item._id !== action.payload._id
      );
      state.subCategories = newSubCatList;
      return state;
    default:
      return state;
  }
}
