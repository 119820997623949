import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState";

export default function changeCurrentSubCategoryReducer(state=initialState.currentSubCategory,action){
    switch (action.type) {
        case actionTypes.CHANGE_CURRENT_SUBCATEGORY:
            return action.payload
        default:
            return state;
    }
}