import React, { Component } from "react";
import { Container } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import * as businessActions from "../../../redux/actions/businessActions";
import alertify from "alertifyjs";
import { saveBusiness } from "../../../redux/actions/businessActions";
import axios from "axios";
import * as APIURLS from "../../util/ApiUrls";
import { withTranslation } from "react-i18next";

class AddYourBusiness extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.actions.changeCurrentBusiness({});
    this.props.actions.getCategories();
  }
  required = (value) => {
    const { t } = this.props;
    const text = t("generalform.required");
    return value ? undefined : text;
  };

  email = (value) => {
    const { t } = this.props;
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? t("generalform.invalidemailaddress")
      : undefined;
  };

  renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
    <div>
      <label>{label}</label>
      <div>
        <input
          className="form-control"
          {...input}
          placeholder={label}
          type={type}
        />
        {touched &&
          ((error && (
            <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  renderTextArea = ({
    input,
    label,
    type,
    rows,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <textarea
          className="form-control"
          {...input}
          placeholder={label}
          rows={rows}
          type={type}
        />
        {touched &&
          ((error && (
            <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  renderSelectField = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <select {...input}>{children}</select>
        {touched && error && (
          <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
        )}
      </div>
    </div>
  );

  selectCategory = (event) => {
    console.log(event.target.value);
    let target = event.target;

    let index = target.selectedIndex;

    console.log(target.children[index]);

    if (index !== 0) {
      let optionElement = target.children[index];
      let id = optionElement.getAttribute("value");

      let selectedCat = this.props.categories.find((item) => item._id === id);
      if (selectedCat)
        this.props.actions.changeCurrentSubCategoryList(
          selectedCat.subCategories
        );
    }
  };

  saveBusiness = async (history, values) => {
    const { t } = this.props;
    const formData = new FormData();
    formData.append("catId", values.catId);
    formData.append("subCatId", values.subCatId);
    formData.append("name", values.name);
    formData.append("address", values.address);
    formData.append("email", values.email);
    formData.append("phone", values.phone);

    let url = APIURLS.ADD_BUSINESS_USER_URL;
    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 201) {
          this.props.actions.changeCurrentBusiness(response.data.business);
          history.push("/userbusinesssavedsuccess");
        }
      })
      .catch((err) => {
        debugger;
        if (err.response) {
          alertify.error(err.response + ":" + err.message);
        } else if (err.request) {
          alertify.error(t("addyourbusinessform.noresponseerror"));
        } else {
          alertify.error(t("addyourbusinessform.uexpectederror"));
        }
      });
  };

  render() {
    const { t, history, handleSubmit, submitting } = this.props;

    return (
      <Container className="containerform font-label">
        <h2 className="addyourbusiness-title" style={{ textAlign: "center" }}>
          {t("addyourbusinessform.title")}
        </h2>
        <form
          onSubmit={handleSubmit((values) => {
            this.saveBusiness(history, values);
          })}
        >
          <div className="container" style={{ paddingBottom: "60px" }}>
            <div className="row">
              <div className="col-25">
              </div>
              <div className="col-75">
                <Field
                  name="name"
                  component={this.renderField}
                  type="text"
                  label={t("addyourbusinessform.businessname")}
                  validate={[this.required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
              </div>
              <div className="col-75">
                <Field
                  name="phone"
                  component={this.renderField}
                  type="text"
                  label={t("addyourbusinessform.phone")}
                  validate={[this.required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
              </div>
              <div className="col-75">
                <Field
                  name="email"
                  component={this.renderField}
                  type="text"
                  label={t("addyourbusinessform.email")}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-25">
              </div>
              <div className="col-75">
                <Field
                  name="catId"
                  label={t("addyourbusinessform.category")}
                  component={this.renderSelectField}
                  onChange={this.selectCategory}
                  validate={[this.required]}
                >
                  <option />
                  {this.props.categories.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="row">
              <div className="col-25">
    
              </div>
              <div className="col-75">
                <Field
                  name="subCatId"
                  label={t("addyourbusinessform.subcategory")}
                  component={this.renderSelectField}
                  onChange={this.selectCategory}
                  validate={[this.required]}
                >
                  <option />
                  {this.props.currentSubCategories.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            <div className="row">
              <div className="col-25">
     
              </div>
              <div className="col-75">
                <Field
                  name="address"
                  component={this.renderTextArea}
                  label={t("addyourbusinessform.address")}
                  rows={6}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-25"></div>
              <div className="col-75">
                <button
                  type="submit"
                  disabled={submitting}
                  className="btn btn-primary px-4 float-right"
                >
                  {t("addyourbusinessform.save")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: state.changeCurrentBusinessReducer,
    categories: state.getCategoriesReducer,
    currentCategory: state.changeCurrentCategoryReducer,
    currentSubCategories: state.changeCurrentSubCategoryListReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      saveBusiness: bindActionCreators(saveBusiness, dispatch),
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategoryList: bindActionCreators(
        categoryActions.changeCurrentSubCategoryList,
        dispatch
      ),
      changeCurrentBusiness: bindActionCreators(
        businessActions.changeCurrentBusiness,
        dispatch
      ),
    },
  };
};

export default withTranslation("common")(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: "AddYourBusinessForm",
      enableReinitialize: true,
    })
  )(AddYourBusiness)
);
