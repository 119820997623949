import * as actionTypes from "./actionTypes";
import * as APIURLS from "../../components/util/ApiUrls"
import {handleResponse,handleError} from "../../components/util/handleError";

export function getCategoriesSuccess(categories){
    return {type: actionTypes.GET_CATEGORIES_SUCCESS, payload: categories}
}

//   export function getCategories() {
//   return function(dispatch) {
//     let url = "http://localhost:3000/categories";
//   Axios.get(url)
//     .then(result => dispatch(getCategoriesSuccess(result.data.categoryList))).catch(err => {
      
//     });;
//   };
// }

export function getCategories() {
  return function(dispatch) {
    return fetch(APIURLS.GET_CATEGORIES)
      .then(response => response.json())
      .then(result => dispatch(getCategoriesSuccess(result.categoryList)));
  };
}

export function changeCurrentCategory(category){
    return {type: actionTypes.CHANGE_CURRENT_CATEGORY, payload: category};
}

export function changeCurrentSubCategoryList(subCategoryList){
    return {type: actionTypes.CHANGE_CURRENT_SUBCATEGORY_LIST, payload: subCategoryList};
}
export function changeCurrentSubCategory(subCategory){
    return {type: actionTypes.CHANGE_CURRENT_SUBCATEGORY, payload: subCategory};
}

export function saveCategoryApi(category) {
    let url = "";
    let meth = "";

    const userInfo=JSON.parse(localStorage.getItem("userInfo"));

    if (category._id) {
      url = APIURLS.UPDATE_CATEGORY_URL + category._id;
      meth = "PUT";
    } else {
      url = APIURLS.ADD_CATEGORY_URL;
      meth = "POST";
    }
    return fetch(url, {
      method: meth,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Accept": "application/json; charset=UTF-8",
        'Authorization': 'Bearer ' +userInfo.token,
      },
      body: JSON.stringify(category)
    })
      .then(handleResponse)
      .catch(handleError);
  }
  
  export function saveCategory(category) {
    return function(dispatch) {
      return saveCategoryApi(category)
        .then(result => {
           if (result.category) {
            dispatch(saveCategorySuccess(result.category));
            dispatch(changeCurrentCategory(result.category));
          }
        })
        .catch(error => {
          throw error;
        });
    };
  }
  
  export function saveCategorySuccess(category) {
    return { type: actionTypes.SAVE_CATEGORY_SUCCESS, payload: category };
  }

  export function deleteCategorySuccess(category) {
    return { type: actionTypes.DELETE_CATEGORY_SUCCESS, payload: category };
  }

  export function deleteCategoryApi(id) {
      let url = APIURLS.DELETE_CATEGORY_URL + id;
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      return fetch(url,{
        "method": "GET",
        "headers": {"Authorization": "Bearer " + userInfo.token}
      })
        .then(handleResponse)
        .catch(handleError);
  }

  export function deleteCategory(id) {
    return function(dispatch) {
      return deleteCategoryApi(id).catch(error => {
        throw error;
      });
    };
  }