import * as actionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default function sendMessageReducer(
  state = initialState.messageResponse,
  action
) {
  switch (action.type) {
    case actionTypes.MESSAGE_SEND_SUCCESS: {
      return action.payload;
    }
    default:
      return state;
  }
}
