import React, { Component } from "react";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import * as businessActions from "../../../redux/actions/businessActions";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import {
  selectCategory,
  selectSubCategory,
} from "../../util/SelectCategorySubCategory";

class SearchBusiness extends Component {
  componentDidMount() {
    this.props.actions.getCategories();
  }

  selectCategory = (event) => {
    selectCategory(event, this.props);
  };

  selectSubCategory = (event) => {
    selectSubCategory(event, this.props);
  };

  render() {
    const { t } = this.props;
    const { handleSubmit, submitting } = this.props;
    return (
      <Container style={{ paddingTop: "12px", paddingBottom: "0px" }}>
        <Row>
          <Col lg="6">
            <form
              onSubmit={handleSubmit((values) => {
                let catName = this.props.currentCategory.linkname;
                let subCatName = this.props.currentSubCategory.linkname;
                this.props.actions.getBusinessListByAdmin(catName, subCatName);
              })}
            >
              <div>
                <div>
                  <Field
                  id="catselect"
                    name="catId"
                    component="select"
                    onChange={this.selectCategory}
                    className="form-control"
                  >
                    <option>{t("searchcomponent.selectcategory")}</option>
                    {this.props.categories.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>

              <div style={{ marginTop: "5px" }}>
                <div>
                  <Field
                    name="subCatId"
                    className="form-control"
                    component="select"
                    onChange={this.selectSubCategory}
                  >
                    <option>{t("searchcomponent.selectsubcategory")}</option>
                    {this.props.currentSubCategories.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>

              <button
                style={{ marginTop: "5px" }}
                type="submit"
                disabled={submitting}
                className="btn btn-primary px-4 float-right"
              >
                {t("searchcomponent.search")}
              </button>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      catId: state.changeCurrentCategoryReducer._id,
      subCatId: state.changeCurrentSubCategoryReducer._id,
    },
    categories: state.getCategoriesReducer,
    currentCategory: state.changeCurrentCategoryReducer,
    currentSubCategories: state.changeCurrentSubCategoryListReducer,
    currentSubCategory: state.changeCurrentSubCategoryReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategoryList: bindActionCreators(
        categoryActions.changeCurrentSubCategoryList,
        dispatch
      ),
      changeCurrentSubCategory: bindActionCreators(
        categoryActions.changeCurrentSubCategory,
        dispatch
      ),
      getBusinessListByAdmin: bindActionCreators(
        businessActions.getBusinessListByAdmin,
        dispatch
      ),
    },
  };
}

export default withTranslation("common")(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: "SearchBusinessForm",
    })(SearchBusiness)
  )
);
