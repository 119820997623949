import DirectoryError from './DirectoryError'

export async function handleResponse(response){
    if(response.ok){
      return response.json()
    }
  
    const error = await response.text();
    throw new DirectoryError(response,error);
  }
  
  export function handleError(error){
    console.error("An Error occurred"+error.name)
    throw error;
  }