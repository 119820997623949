
import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState';

export default function loginReducer(state=initialState.user,action){
    switch(action.type){
        case actionTypes.LOGIN_SUCCESS:
           {
               localStorage.setItem("userInfo",JSON.stringify(action.payload)); 
               return action.payload;
           } 

           case actionTypes.LOGOUT:
               {
                   localStorage.removeItem("userInfo");
                   return action.payload;
               }

        default :return state;
    }
}