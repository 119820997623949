import React, { Component } from "react";
import { Button } from "reactstrap";
import i18n from "i18next";
import { useHistory, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as businessActions from "../../redux/actions/businessActions";
class ChangeLanguage extends Component {
    render(){
    const {t}=this.props;
    return (<div>
        {/* <Button color="success" style={{marginLeft:"5px", marginRight:"5px"}} onClick={async () =>{i18n.changeLanguage('es'); await this.props.actions.getBusinessListByCategory("undefined", "undefined", 1);  this.props.history.push(t('appurl.home'))} }>es</Button> */}
        <Button color="danger" style={{ marginRight:"5px"}} onClick={ async() => {i18n.changeLanguage('tr'); await this.props.actions.getBusinessListByCategory("undefined", "undefined", 1);  this.props.history.push(t('appurl.home'))} }>tr</Button>
        <Button color="primary" onClick={ async() => {i18n.changeLanguage('en'); await this.props.actions.getBusinessListByCategory("undefined", "undefined", 1);   this.props.history.push(t('appurl.home'))} }>en</Button>
    </div>)
} 
 }


  
  function mapDispatchToProps(dispatch) {
    return {
      actions: {
        getBusinessListByCategory: bindActionCreators(
            businessActions.getBusinessListByCategory,
            dispatch
          )
      },
    };
  }

export default withTranslation('common') (withRouter(connect(null, mapDispatchToProps)(ChangeLanguage)));