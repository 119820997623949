import React, { Component, Suspense } from "react";
import MyNavBar from "./components/navbar/MyNavBar";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import About from "./components/commonui/About";
import { NotFound } from "./components/commonui/NotFound";
import BusinessForm from "./components/business/management/BusinessForm";
import CategoryForm from "./components/category/management/CategoryForm";
import CategoryList from "./components/category/management/CategoryList";
import SubCategoryEditList from "./components/category/management/SubCategoryEditList";
import CategorySuccess from "./components/commonui/CategorySuccess";
import BusinessSuccess from "./components/commonui/BusinessSuccess";
import SubCategoryList from "./components/category/management/SubCategoryList";
import SubCategoryForm from "./components/category/management/SubCategoryForm";
import BusinessResultList from "./components/root/BusinessResultList";
import LoginForm from "./components/login/LoginForm";
import { MessageSendSuccess } from "./components/commonui/MessageSendSuccess";
import ContactForm from "./components/commonui/ContactForm";
import MessageList from "./components/message/MessageList";
import Footer from "./components/commonui/Footer";
import { withTranslation } from "react-i18next";
import AddYourBusiness from "./components/business/user/AddYourBusiness";
import { BrowserView, MobileView } from "react-device-detect";
import MobileFooter from "./components/commonui/MobileFooter";
import MainPage from "./components/root/MainPage";
import BusinessManagementList from "./components/business/management/BusinessManagementList";
import SubCategorySuccess from "./components/commonui/SubCategorySuccess ";
import { UserBusinessSavedSuccess } from "./components/commonui/UserBusinessSavedSuccess";
import IdleTimer from "react-idle-timer";
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 15 * 60 * 1000,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };

      this.idleTimer = null;
      this.onAction = this._onAction.bind(this);
      this.onActive = this._onActive.bind(this);
      this.onIdle = this._onIdle.bind(this);
    
  }

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut && this.isLogged()) {
      localStorage.removeItem("userInfo");
      this.props.history.push("/login");
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  isLogged = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let isLogged = false;

    if (userInfo) {
      return (isLogged = userInfo.token ? true : false);
    }

    return isLogged;
  };

  render() {
    const { t } = this.props;
   const loggedIn=this.isLogged();

    return (
      <div className="App">
        <Suspense fallback="loading">
         {loggedIn? <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout}
          />:null}

          <MyNavBar />

          <Switch>
            <Route exact path="/" component={MainPage} />

            <Route exact path="/home" component={BusinessResultList} />

            <Redirect exact from="/yahyali-firmalar" to="/home" />

            <Redirect exact from="/hogar" to="/home" />

            <Route exact path="/home/:catName" component={BusinessResultList} />

            <Redirect
              exact
              from="/yahyali-firmalar/:catName"
              to="/home/:catName"
            />

            <Redirect exact from="/hogar/:catName" to="/home/:catName" />

            <Route
              exact
              path="/home/:catName/:subCatName"
              component={BusinessResultList}
            />

            <Redirect
              exact
              from="/yahyali-firmalar/:catName/:subCatName"
              to="/home/:catName/:subCatName"
            />

            <Redirect
              exact
              from="/hogar/:catName/:subCatName"
              to="/home/:catName/:subCatName"
            />

            {/* <Route
              exact
              path={t("appurl.home")}
              component={BusinessResultList}
            /> */}

            <Route exact path={t("appurl.about")} component={About} />

            <Route exact path={t("appurl.contact")} component={ContactForm} />

            {/* 
            <Route
              exact
              path={t("appurl.home") + ":catName/"}
              component={BusinessResultList}
            />

            <Route
              exact
              path={t("appurl.home") + ":catName/:subCatName/"}
              component={BusinessResultList}
            /> */}

            <Route
              exact
              path={t("appurl.addyourbusiness")}
              component={AddYourBusiness}
            />

            <Route
              exact
              path="/userbusinesssavedsuccess"
              component={UserBusinessSavedSuccess}
            />

            <Route
              exact
              path="/messagesendsuccess"
              component={MessageSendSuccess}
            />

            <Route exact path="/login" component={LoginForm} />

            {this.isLogged() ? (
              <Route exact path="/businessform" component={BusinessForm} />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route exact path="/businessform/new" component={BusinessForm} />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route exact path="/categoryform" component={CategoryForm} />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route exact path="/categoryform/new" component={CategoryForm} />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route
                exact
                path="/subcategoryform"
                component={SubCategoryForm}
              />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route
                exact
                path="/subcategoryform/new"
                component={SubCategoryForm}
              />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route exact path="/categorylist" component={CategoryList} />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route
                exact
                path="/subcategorylist"
                component={SubCategoryList}
              />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route
                exact
                path="/subcategoryeditlist"
                component={SubCategoryEditList}
              />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route
                exact
                path="/businesslist"
                component={BusinessManagementList}
              />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route
                exact
                path="/categorysavedsuccess"
                component={CategorySuccess}
              />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route
                exact
                path="/subcategorysavedsuccess"
                component={SubCategorySuccess}
              />
            ) : (
              "null"
            )}

            {this.isLogged() ? (
              <Route
                exact
                path="/businesssavedsuccess"
                component={BusinessSuccess}
              />
            ) : (
              "null"
            )}

            {/* <Route
              exact
              path="/businessdetail/:businessId"
              component={BusinessDetail}
            /> */}

            {this.isLogged() ? (
              <Route exact path="/messagelist" component={MessageList} />
            ) : (
              "null"
            )}

            {/* <Route exact path="/:id" component={MainPage} /> */}

            <Route component={NotFound} />
          </Switch>
          <BrowserView>
            <Footer />
          </BrowserView>
          <MobileView>
            <MobileFooter />
          </MobileView>
        </Suspense>
      </div>
    );
  }
}

export default withTranslation("common")(withRouter(App));
