import React, { Component } from "react";
import { login } from "../../redux/actions/loginActions";
import alertify from "alertifyjs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class LoginForm extends Component {
  state = { email: "", password: "" };

  handleChange = (event) => {
    let target = event.target;
    this.setState({ [target.name]: target.value });
  };

  handleSubmit = async (event) => {
    const { t } = this.props;
    event.preventDefault();
    try {
      await this.props.actions.login(this.state);
      if (this.props.user.token) {
        this.props.history.push("/");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400)
          alertify.error(t("login.incorrectusernamepassword"));
        else {
          alertify.error(t("login.unexpectederror"));
        }
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div className="card card-container">
        <img
          alt=""
          id="profile-img"
          className="profile-img-card"
          src="/icons/user.png"
        />
        <p id="profile-name" className="profile-name-card"></p>
        <form onSubmit={this.handleSubmit} className="form-signin">
          <span id="reauth-email" className="reauth-email"></span>
          <input
            type="email"
            name="email"
            id="inputEmail"
            className="form-control"
            placeholder={t("login.email")}
            onChange={this.handleChange}
            required
            autoFocus
          />
          <input
            type="password"
            name="password"
            id="inputPassword"
            className="form-control"
            placeholder={t("login.password")}
            onChange={this.handleChange}
            required
          />
          {/* <div id="remember" className="checkbox">
                        <label>
                            <input type="checkbox" value="remember-me"/> Remember me
                        </label>
                    </div> */}
          <button
            className="btn btn-lg btn-primary btn-block btn-signin"
            type="submit"
          >
            {t("login.sigin")}
          </button>
        </form>
        {/* <a href="#" className="forgot-password">
                    Forgot the password?
                </a> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.loginReducer };
}

function mapDispatchToProps(dispatch) {
  return { actions: { login: bindActionCreators(login, dispatch) } };
}

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
