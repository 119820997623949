import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Container, Row, Col } from "reactstrap";

class About extends Component {

  componentDidMount(){
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    return (
      <Container style={{marginBottom:"70px"}}>
        <Row>
          <Col>
            <h2 className="about-title centersuccess">{t("about.title")}</h2>
          </Col>
        </Row>

        <Row>
          <Col xl="2">
            <img
              width="100px"
              height="100px"
              alt="logo"
              src={t("navbar.logopath")}
            ></img>
          </Col>

          <Col>
            <h4 className="about-desc">{t("about.description")}</h4>
          </Col>
        </Row>
    

        <Row>
          <Col xs={{ size: 8, offset: 2 }} md={{ size: 8, offset: 3 }} >
            <a href={t("appurl.addyourbusiness")}>
              <Button color="warning">{t("navbar.addyourbusiness")}</Button>
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation("common")(About);
