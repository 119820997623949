import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class BusinessSuccess extends Component {
  componentDidMount=()=> {
}
  render() {
const {t}=this.props;

    return (
      <div>
        <div>
    <h3 className="centersuccess">{t('success.businesssavedsuccess')}</h3>
        </div>
        <div className="centersuccess">
          <img
            src="./images/tick.png"
            alt="Tick"
          />{" "}
        </div>
        <hr></hr>
      </div>
    );
  }
}


export default withTranslation('common') (BusinessSuccess);