import React, { Component } from "react";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../redux/actions/categoryActions";
import * as businessActions from "../../redux/actions/businessActions";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import { withTranslation } from "react-i18next";
import {selectCategory,selectSubCategory} from "../util/SelectCategorySubCategory";
import initialState from "../../redux/reducers/initialState";

class MainPage extends Component {
  componentDidMount = () => {
    this.props.actions.changeCurrentCategory({});
    this.props.actions.changeCurrentSubCategory({});
    this.props.actions.changeCurrentSubCategoryList([]); 
    this.props.actions.getCategories();
  };

  selectCategory = (event) => {
       selectCategory(event,this.props); 
  };

  selectSubCategory = (event) => {
    selectSubCategory(event,this.props); 
  };
  //

  getLink = () => {
    this.props.actions.changeBusinessList(initialState.businessList);
    let link = this.props.t('appurl.home');
    let categoryName = this.props.currentCategory.linkname;
    let subCategoryName = this.props.currentSubCategory.linkname;

    if (categoryName) {
      link = link.concat(categoryName+"/");
    }

    if (subCategoryName) {
      link = link.concat(subCategoryName+"/");
    }
    return link;
  };

  render() {
    const {t}=this.props;
    return (
        <div
          className="s01"
          style={{
            backgroundImage: "url(" + t("searchcomponent.imagepath") + ")",
          }}
        >
          <form>
            <fieldset>
              {/* <legend>{t('searchcomponent.heading',{framework:a.a})}</legend> */}
              <div className="transbox">
                {" "}
                <legend className="legendh">
                  {t("searchcomponent.heading")}
                </legend>
              </div>
            </fieldset>
            <div className="inner-form">
              <div className="input-field second-wrap">
                <select name="catId" id="search" onChange={this.selectCategory}>
                  <option>{t("searchcomponent.selectcategory")}</option>
                  {this.props.categories.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field second-wrap">
                <select name="subCatId" onChange={this.selectSubCategory}>
                  <option>{t("searchcomponent.selectsubcategory")}</option>
                  {this.props.currentSubCategories.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-field third-wrap">
                <Link to={this.getLink()}>
                  <Button className="btn-search">
                    {t("searchcomponent.search")}
                  </Button>
                </Link>
              </div>
            </div>
          </form>
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    categories: state.getCategoriesReducer,
    currentCategory: state.changeCurrentCategoryReducer,
    currentSubCategories: state.changeCurrentSubCategoryListReducer,
    currentSubCategory: state.changeCurrentSubCategoryReducer,
    prop: ownProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategoryList: bindActionCreators(
        categoryActions.changeCurrentSubCategoryList,
        dispatch
      ),
      changeCurrentSubCategory: bindActionCreators(
        categoryActions.changeCurrentSubCategory,
        dispatch
      ),
      changeBusinessList: bindActionCreators(
        businessActions.getBusinessListSuccess,
        dispatch
      )
    },
  };
}

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(MainPage)
);


