import React, { Component } from "react";
import { Container } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  saveCategory,
  changeCurrentCategory,
} from "../../../redux/actions/categoryActions";
import { withTranslation } from "react-i18next";

class CategoryForm extends Component {
  required = (value) => {
    const { t } = this.props;
    const text = t("generalform.required");
    return value ? undefined : text;
  };

  renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
    <div>
      <label>{label}</label>
      <div>
        <input
          className="form-control"
          {...input}
          placeholder={label}
          type={type}
        />
        {touched &&
          ((error && (
            <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  renderTextArea = ({
    input,
    label,
    type,
    rows,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <textarea
          className="form-control"
          {...input}
          placeholder={label}
          rows={rows}
          type={type}
        />
        {touched &&
          ((error && (
            <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  render() {
    const { t, history, handleSubmit, submitting } = this.props;
    let id = this.props.initialValues._id;
    let editmode = false;

    if (id) {
      editmode = true;
    }

    return (
      <Container style={{ marginBottom: "50px" }}>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <h2 style={{ textAlign: "center" }}>{t("categoryform.title")}</h2>
              <form
                onSubmit={handleSubmit((values) => {
                  console.log(JSON.stringify(values, null, 2));
                  this.props.actions.saveCategory(values).then(() => {
                    history.push("/categorysavedsuccess");
                  });
                })}
              >
                {editmode === true ? (
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <Field
                        name="createdDate"
                        component="input"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                ) : null}

                <div className="form-group row">
                  <div className="col-sm-6">
                    <Field
                      name="name"
                      component={this.renderField}
                      label={t("categoryform.name")}
                      type="text"
                      validate={[this.required]}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="linkname"
                      component={this.renderField}
                      label={t("generalform.linkname")}
                      type="text"
                      validate={[this.required]}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6">
                    <Field
                      name="desc"
                      component={this.renderTextArea}
                      rows="4"
                      label={t("generalform.desc")}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-12">
                    {editmode === true ? (
                      <button
                        type="submit"
                        disabled={submitting}
                        className="btn btn-primary px-4 float-right"
                      >
                        {t("generalform.update")}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={submitting}
                        className="btn btn-primary px-4 float-right"
                      >
                        {t("generalform.save")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (window.location.pathname === "/categoryform/new") {
    return { initialValues: {} };
  } else {
    return {
      initialValues: state.changeCurrentCategoryReducer,
      // initialValues: {
      //   name: state.changeCurrentCategoryReducer.name,
      //   desc: state.changeCurrentCategoryReducer.desc,
      //   _id: state.changeCurrentCategoryReducer._id,
      // },
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      // saveExam: bindActionCreators(saveExam, dispatch),
      saveCategory: bindActionCreators(saveCategory, dispatch),
      changeCurrentCategory: bindActionCreators(
        changeCurrentCategory,
        dispatch
      ),
    },
  };
};

export default withTranslation("common")(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: "CategoryForm",
      enableReinitialize: true,
    })(CategoryForm)
  )
);
