import React, { Component } from "react";
import { Table, Container, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import { withTranslation } from "react-i18next";
class CategoryList extends Component {
  componentDidMount() {
    this.props.actions.getCategories();
  }

  changeCurrentCategory = (item) => {
    this.props.actions.changeCurrentCategory(item);
    this.props.actions.changeCurrentSubCategoryList(item.subCategories);
  };

  deleteHandler = (id) => {
    try {
      this.props.actions.deleteCategory(id).then((response) => {
        this.props.actions.deleteCategorySuccess(response.category);
        console.log(JSON.stringify(response));
        alertify.success("Category has been deleted");
      });
    } catch (error) {
      alertify.error("An error occurred while category is being deleted");
    }
  };

  render() {
    const {t}=this.props;
    return (
      <Container>
        <h2 style={{ textAlign: "center" }}>{t('categorylist.title')}</h2>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>{t('categorylist.name')}</th>
              <th>{t('categorylist.linkname')}</th>
              <th>{t('categorylist.createdate')}</th>
              <th>{t('categorylist.desc')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.categories.map((item, index) => (
              <tr key={index}>
                <th>{index + 1}</th>
                <td>{item.name}</td>
                <td>{item.linkname}</td>
                <td>{item.createdDate}</td>
                <td>{item.desc}</td>
                <td>
                  <Link to="/categoryform">
                    <Button
                      onClick={() => this.changeCurrentCategory(item)}
                      color="primary"
                    >
                      {t('categorylist.edit')}
                    </Button>
                  </Link>
                </td>
                <td>
                  <Link to="/subcategorylist">
                    <Button
                      onClick={() => this.changeCurrentCategory(item)}
                      color="success"
                    >
                      {t('categorylist.showsubcategories')}
                    </Button>
                  </Link>
                </td>
                <td>
                  <Button
                    color="warning"
                    onClick={() => {
                      this.deleteHandler(item._id);
                    }}
                  >
                     {t('categorylist.delete')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    categories: state.getCategoriesReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),

      changeCurrentSubCategoryList: bindActionCreators(
        categoryActions.changeCurrentSubCategoryList,
        dispatch
      ),
      deleteCategory: bindActionCreators(
        categoryActions.deleteCategory,
        dispatch
      ),
      deleteCategorySuccess: bindActionCreators(
        categoryActions.deleteCategorySuccess,
        dispatch
      ),
    },
  };
}

export default withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(CategoryList));
