import React, { Component } from 'react'

import { FieldArray, Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { saveCategory } from '../../../redux/actions/categoryActions'

class SubCategoryEditList extends Component {
  renderField = ({ input, label, type, meta: { touched, error } }) => (
    <div>
      <label>{label}</label>
      <div>
        <input {...input} type={type} placeholder={label} />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );

  renderFieldTextArea = ({
    input,
    label,
    type,
    rows,
    meta: { touched, error }
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <textarea {...input} type={type} placeholder={label} rows={rows} />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );

  renderSubCategories = ({ fields, meta: { touched, error, submitFailed } }) => (
    <div>
      <div>
        <button
          className="fa fa-plus-circle"
          type="button"
          onClick={() => fields.push({})}
        >
         
        </button>
      </div>
      {(touched || submitFailed) && error && <span>{error}</span>}

      {fields.map((member, index) => (
        <div key={index} className="form-group row">
          <div className="col-sm-3">
            <button
              className="fa fa-remove"
              type="button"
              title="Delete Subcategory"
              onClick={() => fields.remove(index)}
            >
         
            </button>
          </div>

          <div className="col-sm-6">
          <Field
              name={`${member}.name`}
              type="text"
              component={this.renderField}
              label="Name"
            />
          </div>

          <div className="col-sm-3">
          <Field
              name={`${member}.desc`}
              type="textarea"
              rows="3"
              component={this.renderFieldTextArea}
              label="Description"
            />
          </div>
        </div>
      ))}
    </div>
  );

  render() {
    const { history, handleSubmit, pristine, reset, submitting } = this.props;
    let editmode = false;
    console.log("initial.values",this.props.initialValues);
    if (this.props.initialValues._id) {
      editmode = true;
    }else{
        this.props.history.push("/categorylist");
    }

    return (
      <div className="container py-5">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <h3>SUBCATEGORY FORM</h3>
            <form onSubmit={handleSubmit((values)=>{
                 console.log(JSON.stringify(values, null, 2));
                 this.props.actions.saveCategory(values).then(() => {
                    history.push("/businesssavesuccess");
                  });
            })}>

              <div className="form-group row">
                <div className="col-sm-12">
                  <label htmlFor="SubCategories">SubCategories</label>
                  <FieldArray
                    name="subCategories"
                    component={this.renderSubCategories}
                  />
                </div>
              </div>
              <hr />

              {editmode === true ? (
                <button
                  type="submit"
                  disabled={submitting}
                  className="btn btn-primary px-4 float-right"
                >
                  UPDATE
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={submitting}
                  className="btn btn-primary px-4 float-right"
                >
                  SAVE
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: state.changeCurrentCategoryReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: {
      saveCategory: bindActionCreators(saveCategory, dispatch)
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "SubCategoryForm"
  })
)(SubCategoryEditList);

