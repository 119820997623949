import React  from "react";

export const  getResultText = (t, length) => {
    let result;
    if (length === 0) {
      result = (
        <span
          className="centersuccess"
          style={{ fontSize: "20px", color: "red", margin: "30px" }}
        >
          {t('businesslist.0resultfound')}
        </span>
      );
    } else if (length === 1) {
      result = (
        <span
          className="centersuccess"
          style={{ fontSize: "20px", color: "red", margin: "30px" }}
        >
         {t('businesslist.1resultfound')}
        </span>
      );
    } 
    return result;
  };