import * as actionTypes from "./actionTypes";
import * as APIURL from "../../components/util/ApiUrls";
import { handleError, handleResponse } from "../../components/util/handleError";

export function getMessagesSuccess(messages) {
  return { type: actionTypes.GET_MESSAGES_SUCCESS, payload: messages };
}

export function getMessages() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return function (dispatch) {
    return fetch(APIURL.GET_MESSAGES_URL, {
      "method": "GET",
      headers: {"Authorization": "Bearer " + userInfo.token}
    })
      .then((response) => response.json())
      .then((result) => dispatch(getMessagesSuccess(result.messageList)));
  };
}

export function sendMessageSuccess(messageResponse) {
  return { type: actionTypes.MESSAGE_SEND_SUCCESS, payload: messageResponse };
}
export function sendMessageApi(message) {
  let url = APIURL.MESSAGE_SEND_URL;
  let meth = "POST";

  return fetch(url, {
    method: meth,
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Accept: "application/json; charset=UTF-8",
    },
    body: JSON.stringify(message),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function sendMessage(message) {
  return function (dispatch) {
    return sendMessageApi(message)
      .then((messageResponse) => {
        dispatch(sendMessageSuccess(messageResponse));
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
}
