export const GET_CATEGORIES_SUCCESS="GET_CATEGORIES_SUCCESS";
export const CHANGE_CURRENT_CATEGORY="CHANGE_CURRENT_CATEGORY";
export const CHANGE_CURRENT_SUBCATEGORY_LIST="CHANGE_CURRENT_SUBCATEGORY_LIST";
export const CHANGE_CURRENT_SUBCATEGORY="CHANGE_CURRENT_SUBCATEGORY";
export const SAVE_CATEGORY_SUCCESS="SAVE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_SUCCESS="DELETE_CATEGORY_SUCCESS"

export const SAVE_SUBCATEGORY_SUCCESS="SAVE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_SUCCESS="DELETE_SUBCATEGORY_SUCCESS"

export const CHANGE_CURRENT_BUSINESS="CHANGE_CURRENT_BUSINESS";
export const GET_BUSINESSLIST_SUCCESS="GET_BUSINESSLIST_SUCCESS";
export const SAVE_BUSINESS_SUCCESS="SAVE_BUSINESS_SUCCESS";
export const DELETE_BUSINESS_SUCCESS="DELETE_BUSINESS_SUCCESS"

export const DELETEFULL_BUSINESS_SUCCESS="DELETEFULL_BUSINESS_SUCCESS"


export const LOGIN_SUCCESS="LOGIN_SUCCESS";
export const LOGOUT="LOGOUT";

export const GET_MESSAGES_SUCCESS="GET_MESSAGES_SUCCESS";
export const MESSAGE_SEND_SUCCESS="MESSAGE_SEND_SUCCESS";
