import React, { Component } from "react";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
  Container,
} from "reactstrap";
import alertify from "alertifyjs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as businessActions from "../../../redux/actions/businessActions";
import { Link } from "react-router-dom";
import { IMAGEURL_PREFIX } from "../../util/ApiUrls";
import SearchBusiness from "./SearchBusiness";
import { withTranslation } from "react-i18next";
import { getResultText } from "../../util/ResultText";
import * as categoryActions from "../../../redux/actions/categoryActions";

class BusinessManagementList extends Component {
  componentDidMount = () => {
    this.props.actions.changeCurrentBusiness({});
    // this.props.actions.changeBusinessList([]);
    // this.props.actions.changeCurrentCategory({});
    // this.props.actions.changeCurrentSubCategory({});
  };

  hideHandler = (id) => {
    try {
      this.props.actions.deleteBusiness(id).then((response) => {
        this.props.actions.deleteBusinessSuccess(response.business);
        alertify.success("Business has been hided");
      });
    } catch (error) {
      alertify.error("An error occurred while business is being deleted");
    }
  };

  deleteHandler = (id) => {
    try {
      this.props.actions.deleteFullBusiness(id).then((response) => {
        this.props.actions.deleteFullBusinessSuccess(response.business);
        console.log(JSON.stringify(response));
        alertify.success("Business has been deleted");
      });
    } catch (error) {
      alertify.error("An error occurred while business is being deleted");
    }
  };

  render() {
    const { t } = this.props;

    const isNotEmpty = this.props.businessList.length ? true : false;

    return isNotEmpty ? (
      <Container>
        <Row>
          <Col>
            <SearchBusiness></SearchBusiness>
          </Col>
        </Row>

        <h1>
          {getResultText(
            t,
            this.props.businessList.length ? this.props.businessList.length : 0
          )}
        </h1>
        {this.props.businessList.map((item, i) => (
          <Card key={i}>
            <Row>
              <Col xl="3">
                <CardImg
                  top
                  src={IMAGEURL_PREFIX + item.image}
                  alt="Card image cap"
                />
              </Col>

              <Col>
                <CardBody style={{ padding: "0px" }}>
                  <CardTitle>
                    <span className={item.namestyle}> {item.name}</span>
                  </CardTitle>
                  <CardSubtitle>{item.address}</CardSubtitle>
                  <CardSubtitle>{item.email}</CardSubtitle>
                  <CardText>{item.phone}</CardText>
                  <CardText>
                    {t("businessform.rankscore") + " : " + item.rankscore}
                  </CardText>

                  <Link className="cardbutton" to="/businessform">
                    <Button
                      onClick={() =>
                        this.props.actions.changeCurrentBusiness(item)
                      }
                      color="primary"
                    >
                      {t("businessmanagementlist.update")}
                    </Button>
                  </Link>

                  {!item.deleted ? (
                    <Button
                      color="warning"
                      onClick={() => {
                        this.hideHandler(item._id);
                      }}
                    >
                      {t("businessmanagementlist.hide")}
                    </Button>
                  ) : (
                    <Button
                      color="warning"
                      onClick={() => {
                        this.hideHandler(item._id);
                      }}
                    >
                      {t("businessmanagementlist.show")}
                    </Button>
                  )}
                  <Button
                    color="danger"
                    onClick={() => {
                      this.deleteHandler(item._id);
                    }}
                    style={{ marginLeft: "20px" }}
                  >
                    {t("businessmanagementlist.delete")}
                  </Button>
                </CardBody>
              </Col>
            </Row>
          </Card>
        ))}
      </Container>
    ) : (
      <Container>
        <Row>
          <Col>
            <SearchBusiness></SearchBusiness>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    businessList: state.getBusinessListReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBusinesList: bindActionCreators(
        businessActions.getBusinessList,
        dispatch
      ),
      changeCurrentBusiness: bindActionCreators(
        businessActions.changeCurrentBusiness,
        dispatch
      ),
      deleteBusiness: bindActionCreators(
        businessActions.deleteBusiness,
        dispatch
      ),
      deleteBusinessSuccess: bindActionCreators(
        businessActions.deleteBusinessSuccess,
        dispatch
      ),
      deleteFullBusiness: bindActionCreators(
        businessActions.deleteFullBusiness,
        dispatch
      ),
      deleteFullBusinessSuccess: bindActionCreators(
        businessActions.deleteFullBusinessSuccess,
        dispatch
      ),
      changeBusinessList: bindActionCreators(
        businessActions.getBusinessListSuccess,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategory: bindActionCreators(
        categoryActions.changeCurrentSubCategory,
        dispatch
      ),
    },
  };
}

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(BusinessManagementList)
);
