import React, { Component } from "react";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { Container, Row, Col, Badge } from "reactstrap";
import { withRouter } from "react-router";
import {
  selectCategory,
  selectSubCategory,
} from "../../util/SelectCategorySubCategory";

class SearchForMobile extends Component {
  async componentDidMount() {

    await this.props.actions.getCategories();

    const catLink=this.props.match.params.catName;
    const subCatLink=this.props.match.params.subCatName;

    let cat=this.props.categories.find(item=>item.linkname===catLink);

    if (cat) {
      this.props.actions.changeCurrentCategory(cat);
      // this.props.actions.changeCurrentSubCategory({});
      this.props.actions.changeCurrentSubCategoryList(cat.subCategories);


      let subCat=cat.subCategories.find(item=>item.linkname===subCatLink);
      if (subCat) this.props.actions.changeCurrentSubCategory(subCat);
    }
  }

  selectCategory = (event) => {
    selectCategory(event, this.props);
  };

  selectSubCategory = (event) => {
    selectSubCategory(event, this.props);
  };

  render() {
    const { t } = this.props;
    const { handleSubmit, submitting } = this.props;
    return (
      <Container style={{ paddingTop: "12px", paddingBottom: "0px" }}>
        <Row>
          <Col>
            <h4 style={{ paddingLeft: "15px" }}>
              <Badge color="success">{this.props.currentCategory.name}</Badge>
            </h4>
            <h6 style={{ paddingLeft: "15px" }}>
              <Badge color="secondary">
                {this.props.currentSubCategory.name?this.props.currentSubCategory.name +"("+this.props.currentSubCategory.bcount+")":""}
              </Badge>
            </h6>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
            <form
              onSubmit={handleSubmit((values) => {
                this.props.callFromChild(
                  this.props.currentCategory,
                  this.props.currentSubCategory
                );
              })}
            >
              <div>
                <div>
                  <Field
                    name="catName"
                    component="select"
                    onChange={this.selectCategory}
                    className="form-control"
                  >
                    <option>{t("searchcomponent.selectcategory")}</option>
                    {this.props.categories.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>

              <div style={{ marginTop: "5px" }}>
                <div>
                  <Field
                    name="subCatName"
                    className="form-control"
                    component="select"
                    onChange={this.selectSubCategory}
                  >
                    <option>{t("searchcomponent.selectsubcategory")}</option>
                    {this.props.currentSubCategories.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>

              <button
                style={{ marginTop: "5px" }}
                type="submit"
                disabled={submitting}
                className="btn btn-primary px-4 float-right"
              >
                {t("searchcomponent.search")}
              </button>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      catName: state.changeCurrentCategoryReducer._id,
      subCatName: state.changeCurrentSubCategoryReducer._id,
    },
    categories: state.getCategoriesReducer,
    currentCategory: state.changeCurrentCategoryReducer,
    currentSubCategories: state.changeCurrentSubCategoryListReducer,
    currentSubCategory: state.changeCurrentSubCategoryReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategoryList: bindActionCreators(
        categoryActions.changeCurrentSubCategoryList,
        dispatch
      ),
      changeCurrentSubCategory: bindActionCreators(
        categoryActions.changeCurrentSubCategory,
        dispatch
      ),
    },
  };
}

export default withTranslation("common")(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      reduxForm({
        form: "SearchFormMobile",
        enableReinitialize: true,
      })(SearchForMobile)
    )
  )
);
