import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as businessActions from "../../redux/actions/businessActions";
import * as categoryActions from "../../redux/actions/categoryActions";
import initialState from "../../redux/reducers/initialState";

class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <footer id="footer" className="footer-1">
          <div className="main-footer widgets-dark typo-light">
            <div className="container">
              <div className="row">
                <div>
                  <div className="widget subscribe no-box">
                    <h5 className="widget-title">
                      <img
                        src={t("footer.logopath")}
                        width="30"
                        height="30"
                        alt="logo"
                      />
                      {t("footer.slogan")}
                  
                    </h5>
                    <Link to={t('appurl.addyourbusiness')}>
                      <Button color="warning">{t("footer.adsslogan")}</Button>
                    </Link>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-3">
                  <div className="widget no-box">
                    <h5 className="widget-title">
                      {t("footer.quicklinks")}
                      <span></span>
                    </h5>
                    <ul className="thumbnail-widget">
                      <li>
                        <div className="thumb-content" style={{marginBottom:"5px"}}>
                         
                          <Link to={t("footer.foodlink")}> <Button color="success" onClick={()=>{this.props.actions.changeBusinessList(initialState.businessList); window.scrollTo(0,0);}}>{t("footer.food")}</Button></Link>
                        </div>
                      </li>
                      <li>
                      <div className="thumb-content" style={{marginBottom:"5px"}}>
                        <Link to={t("footer.healthlink")}> <Button color="primary" onClick={()=>{this.props.actions.changeBusinessList(initialState.businessList); window.scrollTo(0,0)}}>{t("footer.health")}</Button></Link>
                        </div>
                      </li>
                      <li>
                      <div className="thumb-content" style={{marginBottom:"5px"}}>
                        <Link to={t("footer.grocerystorelink")}> <Button color="danger" onClick={()=>{this.props.actions.changeBusinessList(initialState.businessList); window.scrollTo(0,0)}}>{t("footer.grocerystore")}</Button></Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-6 col-md-3">
                  <div className="widget no-box">
                    <h5 className="widget-title">
                      <Link to={t('appurl.contact')}>{t("footer.contact")}</Link>
                      <span></span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-copyright">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>{t("footer.copyright")}</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    businessList: state.getBusinessListReducer,
    currentCategory: state.changeCurrentCategoryReducer,
    currentSubCategory: state.changeCurrentSubCategoryReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBusinessListByCategory: bindActionCreators(
        businessActions.getBusinessListByCategory,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategory: bindActionCreators(
        categoryActions.changeCurrentSubCategory,
        dispatch
      ),
      changeBusinessList: bindActionCreators(
        businessActions.getBusinessListSuccess,
        dispatch
      )
    },
  };
}

export default withTranslation("common")(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Footer))
);

