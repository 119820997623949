import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendMessage } from "../../redux/actions/sendMessageActions";
import { withTranslation } from "react-i18next";

class ContactForm extends Component {
componentDidMount(){
  window.scrollTo(0, 0);
}
required = (value) => {
  const { t } = this.props;
  const text = t("generalform.required");
  return value ? undefined : text;
};

email = (value) => {
  const { t } = this.props;
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? t("generalform.invalidemailaddress")
    : undefined;
};

renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div>
    <label>{label}</label>
    <div>
      <input
        className="form-control"
        {...input}
        placeholder={label}
        type={type}
      />
      {touched &&
        ((error && (
          <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
        )) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);
renderTextArea = ({
  input,
  label,
  type,
  rows,
  meta: { touched, error, warning },
}) => (
  <div>
    <label>{label}</label>
    <div>
      <textarea
        className="form-control"
        {...input}
        placeholder={label}
        rows={rows}
        type={type}
      />
      {touched &&
        ((error && (
          <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
        )) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

  render() {
    const { history, handleSubmit, submitting } = this.props;
    const { t } = this.props;
    return (
      <Container  className="font-label">
        <Row>
          <Col>
            <h2
              className="contact-title centersuccess"
            >
              {t("contact.heading")}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <Row>
              <Col>
                <h4 className="contact-desc">{t("contact.description")}</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={{ size: 6, offset: 4 }} md={{ size: 6, offset: 4 }}>
                <img
                  width="100px"
                  height="100px"
                  alt="logo"
                  src={t("navbar.logopath")}
                ></img>
              </Col>
            </Row>
          </Col>

          <Col lg="6">
            <form
              style={{ paddingBottom: "60px" }}
              onSubmit={handleSubmit((values) => {
                this.props.actions.sendMessage(values).then(() => {
                  history.push("/messagesendsuccess");
                });
              })}
            >
              <div>

                <div>
                  <Field name="email" component={this.renderField} label={t("contact.email")} type="text"  validate={[this.required,this.email]} />
                </div>
              </div>

              <div>
                <div>
                  <Field name="name" component={this.renderField} label={t("contact.name")}  type="text"  validate={[this.required]} />
                </div>
              </div>

              <div>
                <div>
                  <Field name="topic" component={this.renderField} label={t("contact.topic")}  type="text"  validate={[this.required]} />
                </div>
              </div>

              <div>
                <div>
                  <Field name="message" component={this.renderTextArea} label={t("contact.message")} rows={4}  validate={[this.required]} />
                </div>
              </div>

              <div className="centersuccess" style={{ marginTop: "10px" }}>
                <button
                  type="submit"
                  disabled={submitting}
                  className="btn btn-primary px-4 float-right"
                >
                  {t("contact.send")}
                </button>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { initialValues: state.sendMessageReducer };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      sendMessage: bindActionCreators(sendMessage, dispatch),
    },
  };
};

export default withTranslation("common")(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: "ContactForm",
    })(ContactForm)
  )
);
