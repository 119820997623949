import * as actionTypes from "./actionTypes";
import * as APIURLS from "../../components/util/ApiUrls"
import {handleResponse,handleError} from "../../components/util/handleError";


  export function saveSubCategoryApi(subCategory) {

    let url = "";
    let meth = "PUT";
    const userInfo=JSON.parse(localStorage.getItem("userInfo"));

    if (subCategory._id) {
      url = APIURLS.UPDATE_SUBCATEGORY_URL;
    } else {
      url = APIURLS.ADD_SUBCATEGORY_URL;
    }
    return fetch(url, {
      method: meth,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Accept: "application/json; charset=UTF-8",
        'Authorization': 'Bearer ' +userInfo.token
        
      },
      body: JSON.stringify(subCategory)
    })
      .then(handleResponse)
      .catch(handleError);
  }
  
  export function saveSubCategory(subCategory) {
    return function(dispatch) {
      return saveSubCategoryApi(subCategory)
        .then(result => {
           if (result.category) {
            dispatch(saveSubCategorySuccess(result.category));
            //dispatch(changecurrentSubCategory(result.subCategory));
          }
        })
        .catch(error => {
          throw error;
        });
    };
  }
  
  export function saveSubCategorySuccess(category) {
    return { type: actionTypes.SAVE_CATEGORY_SUCCESS, payload: category };
  }

  export function deleteSubCategorySuccess(subCategory) {
    return { type: actionTypes.DELETE_SUBCATEGORY_SUCCESS, payload: subCategory };
  }

  export function deleteSubCategoryApi(catId,subCatId) {
      const url = APIURLS.DELETE_SUBCATEGORY_URL + catId+"/"+subCatId;
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      return fetch(url, {
        "method": "GET",
        headers: {"Authorization": "Bearer " + userInfo.token}
      })
        .then(handleResponse)
        .catch(handleError);
  }

  export function deleteSubCategory(catId,subCatId) {
    return function(dispatch) {
      return deleteSubCategoryApi(catId,subCatId).catch(error => {
        throw error;
      });
    };
  }