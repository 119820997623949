import * as actionTypes from "./actionTypes";
import * as APIURLS from "../../components/util/ApiUrls";
import { handleResponse, handleError } from "../../components/util/handleError";

export function getBusinessListSuccess(businessList) {
  return { type: actionTypes.GET_BUSINESSLIST_SUCCESS, payload: businessList };
}

export function getBusinessList(subCategory) {
  return function (dispatch) {
    let url = APIURLS.GET_BUSINESS_LIST_BYSUBCATEGORY + subCategory._id;
    return fetch(url)
      .then((response) => response.json())
      .then((result) => dispatch(getBusinessListSuccess(result.businessList)));
  };
}

export function getBusinessListByAdmin(categoryName, subCategoryName) {
    const url =
      APIURLS.GET_BUSINESS_LIST_ADMIN + categoryName + "/" + subCategoryName;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let methodObj = {
      method: "GET",
      headers: { Authorization: "Bearer " + userInfo.token },
    };

    return function (dispatch) {
      return fetch(url, methodObj)
        .then((response) => response.json())
        .then((result) =>
          dispatch(getBusinessListSuccess(result.businessList))
        );
    };
}

export function getBusinessListByCategory(categoryName, subCategoryName,page) {
  return function (dispatch) {
    debugger;
    let url =
      APIURLS.GET_BUSINESS_BYCAT_SUBCAT + categoryName + "/" + subCategoryName+"?page="+page;
    return fetch(url)
      .then((response) => response.json())
      .then((result) => dispatch(getBusinessListSuccess(result.businessList)));
  };
}

export function changeCurrentBusiness(business) {
  return { type: actionTypes.CHANGE_CURRENT_BUSINESS, payload: business };
}

export function saveBusinessApi(business) {
  let url = "";
  let meth = "";
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (business._id) {
    url = APIURLS.UPDATE_BUSINESS_URL + business._id;
    meth = "PUT";
  } else {
    url = APIURLS.ADD_BUSINESS_URL;
    meth = "POST";
  }
  return fetch(url, {
    method: meth,
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Accept: "application/json; charset=UTF-8",
      Authorization: "Bearer " + userInfo.token,
    },
    body: JSON.stringify(business),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function saveBusiness(business) {
  return function (dispatch) {
    return saveBusinessApi(business)
      .then((result) => {
        if (result.business) {
          dispatch(saveBusinessSuccess(result.business));
          //dispatch(changeCurrentBusiness(result.business));
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function saveBusinessSuccess(business) {
  return { type: actionTypes.SAVE_BUSINESS_SUCCESS, payload: business };
}

export function deleteBusinessSuccess(business) {
  return { type: actionTypes.DELETE_BUSINESS_SUCCESS, payload: business };
}

export function deleteBusinessApi(id) {
  let url = APIURLS.DELETE_BUSINESS_URL + id;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + userInfo.token },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteBusiness(id) {
  return function (dispatch) {
    return deleteBusinessApi(id).catch((error) => {
      throw error;
    });
  };
}


export function deleteFullBusinessSuccess(business) {
  return { type: actionTypes.DELETEFULL_BUSINESS_SUCCESS, payload: business };
}

export function deleteFullBusinessApi(id) {
  let url = APIURLS.DELETEFULL_BUSINESS_URL + id;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + userInfo.token },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteFullBusiness(id) {
  return function (dispatch) {
    return deleteFullBusinessApi(id).catch((error) => {
      throw error;
    });
  };
}
