import { combineReducers } from "redux";

import { reducer as reduxFormReducer } from 'redux-form';
import getCategoriesReducer from "./getCategoriesReducer"
import changeCurrentCategoryReducer from "./changeCurrentCategoryReducer"
import changeCurrentSubCategoryListReducer from "./changeCurrentSubCategoryListReducer"
import changeCurrentSubCategoryReducer from "./changeCurrentSubCategoryReducer"
import getBusinessListReducer from "./getBusinessListReducer"
import changeCurrentBusinessReducer from "./changeCurrentBusinessReducer"
import loginReducer from "./loginReducer"
import sendMessageReducer from "./sendMessageReducer"
import getMessagesReducer from "./getMessagesListReducer"

const rootReducer = combineReducers({
  getCategoriesReducer,
  changeCurrentCategoryReducer,
  changeCurrentSubCategoryListReducer,
  changeCurrentSubCategoryReducer,
  getBusinessListReducer,
  changeCurrentBusinessReducer,
  loginReducer,
  sendMessageReducer,
  getMessagesReducer,
form: reduxFormReducer
});

export default rootReducer;
