export function selectCategory(event, props) {
  debugger;
  props.actions.changeCurrentSubCategory({});

  let target = event.target;

  let index = target.selectedIndex;

  console.log(target.children[index]);

  if (index !== 0) {
    let optionElement = target.children[index];
    let id = optionElement.getAttribute("value");
    let selectedCat = props.categories.find((item) => item._id === id)
    props.actions.changeCurrentCategory(selectedCat);

    if (selectedCat)
      props.actions.changeCurrentSubCategoryList(selectedCat.subCategories);
  } else {
    props.actions.changeCurrentCategory({});
    props.actions.changeCurrentSubCategory({});
    props.actions.changeCurrentSubCategoryList([]);
  }
}

export function selectSubCategory(event, props) {
  console.log(event.target.value);
  let target = event.target;

  let index = target.selectedIndex;

  console.log(target.children[index]);

  if (index !== 0) {
    let optionElement = target.children[index];
    let id = optionElement.getAttribute("value");

    let selectedSubCat = props.currentSubCategories.find(
      (item) => item._id === id
    );

    props.actions.changeCurrentSubCategory(selectedSubCat);
  } else {
    props.actions.changeCurrentSubCategory({});
  }
}
