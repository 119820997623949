import * as actionTypes from "../actions/actionTypes";
import * as APIURL from "../../components/util/ApiUrls"
import {handleError,handleResponse} from "../../components/util/handleError"


export function loginSuccess(userCredential) {
    return { type: actionTypes.LOGIN_SUCCESS, payload: userCredential };
  }


  export function loginApi(user) {
    let url=APIURL.LOGIN_URL;
    let meth="POST";

    return fetch(url, {
        method: meth,
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Accept: "application/json; charset=UTF-8"
        },
        body: JSON.stringify(user)
      })
      .then(handleResponse)
      .catch(handleError);
  }

  export function login(user) {
    return function(dispatch) {
      return loginApi(user)
        .then(userCredential => {
            dispatch(loginSuccess(userCredential));
        })
        .catch(error => {
          console.log(error);
          throw error;
        });
    };
  }

  export function logout(){
    return {type:actionTypes.LOGOUT,payload:{}};
  }
