import React, { Component } from "react";
import { Table, Container} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as sendMessageActions from "../../redux/actions/sendMessageActions"
import { withTranslation } from "react-i18next";

class MessageList extends Component {
  componentDidMount() {
    this.props.actions.getMessages();
  }

  render() {
    const {t}= this.props;
    return (
      <Container>
        <h2 style={{ textAlign: "center" }}>{t('messagelist.title')}</h2>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>{t('messagelist.email')}</th>
              <th>{t('messagelist.name')}</th>
              <th>{t('messagelist.topic')}</th>
              <th>{t('messagelist.message')}</th>
              <th>{t('messagelist.time')}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.messages.map((item, index) => (
              <tr key={index}>
                <th>{index + 1}</th>
                <td>{item.email}</td>
                <td>{item.name}</td>
                <td>{item.topic}</td>
                <td>{item.message}</td>
                <td>{item.createdDate}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    messages: state.getMessagesReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getMessages: bindActionCreators(
        sendMessageActions.getMessages,
        dispatch
      )
    },
  };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(MessageList));
