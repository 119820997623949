import React from "react";

import { useTranslation } from "react-i18next";

export function MessageSendSuccess() {
  const { t} = useTranslation('common');
  return (
    <div>
      <div>
        <h3 className="centersuccess">{t("success.messagesendsuccess")}</h3>
      </div>
      <div className="centersuccess">
        <img src="./images/tick.png" alt="Tick" />{" "}
      </div>
      <hr></hr>
    </div>
  );
}
