import React, { Component } from "react";
import { Table, Container, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import * as subCategoryActions from "../../../redux/actions/subCategoryActions";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import { withTranslation } from "react-i18next";
class SubCategoryList extends Component {
  changeCurrentSubCategory = (item) => {
    this.props.actions.changeCurrentSubCategory(item);
  };

  deleteHandler = (item) => {
    try {
      this.props.actions.deleteSubCategory(item.catId,item._id).then((response) => {
        this.props.actions.changeCurrentSubCategoryList(response.category.subCategories);
        console.log(JSON.stringify(response));
        alertify.success("SubCategory has been deleted");
      });
    } catch (error) {
      alertify.error("An error occurred while category is being deleted");
    }
  };

  render() {

    const {t}=this.props;

    return (
      <Container>
        <h2 style={{ textAlign: "center" }}>{t('subcategorylist.title')}</h2>
        <Table striped>
          <thead>
            <tr>
            <th>#</th>
              <th>{t('subcategorylist.name')}</th>
              <th>{t('subcategorylist.linkname')}</th>
              <th>{t('subcategorylist.createdate')}</th>
              <th>{t('subcategorylist.desc')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.props.currentSubCategories.map((item, index) => (
              <tr key={index}>
                <th>{index + 1}</th>
                <td>{item.name}</td>
                <td>{item.linkname}</td>
                <td>{item.createdDate}</td>
                <td>{item.desc}</td>
                <td>
                  <Link to="/subcategoryform">
                    <Button
                      onClick={() => this.changeCurrentSubCategory(item)}
                      color="success">
                      {t('subcategorylist.edit')}
                    </Button>
                  </Link>
                </td>
                <td>
                  <Button
                    color="warning"
                    onClick={() => {
                      this.deleteHandler(item);
                    }}>
                    {t('subcategorylist.delete')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentCategory: state.changeCurrentCategoryReducer,
    currentSubCategories:state.changeCurrentSubCategoryListReducer,
    currentSubCategory:state.changeCurrentSubCategoryReducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      changeCurrentSubCategory: bindActionCreators(
        categoryActions.changeCurrentSubCategory,
        dispatch
      ),
      deleteSubCategory: bindActionCreators(
        subCategoryActions.deleteSubCategory,
        dispatch
      ),
      deleteSubCategorySuccess: bindActionCreators(
        subCategoryActions.deleteSubCategorySuccess,
        dispatch
      ),
      changeCurrentSubCategoryList: bindActionCreators(
        categoryActions.changeCurrentSubCategoryList,
        dispatch
      )
    },
  };
}

export default withTranslation('common') (connect(mapStateToProps, mapDispatchToProps)(SubCategoryList));
