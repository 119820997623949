import React, { Component } from "react";
import { CardImg, Button, Jumbotron, Row, Col, Container } from "reactstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as businessActions from "../../redux/actions/businessActions";
import { IMAGEURL_PREFIX } from "../util/ApiUrls";
import CategoryMenuList from "../category/user/CategoryMenuList";
import Ads from "../ads/Ads";
import { withTranslation } from "react-i18next";
import { BrowserView, MobileView } from "react-device-detect";
import SearchForMobile from "../business/user/SearchForMobile";
import { getResultText } from "../util/ResultText";
import * as categoryActions from "../../redux/actions/categoryActions";
import { Link, withRouter } from "react-router-dom";
import initialState from "../../redux/reducers/initialState";

class BusinessResultList extends Component {
  // state = { msg: "", categoryName: "", subCategoryName: "" };

loadPage=()=>{
  let catLink = this.props.match.params.catName;
    let subCatLink = this.props.match.params.subCatName;

    const params = new URLSearchParams(this.props.location.search);
    const page = parseInt(params.get("page")) || 1;
    if (page !== this.props.businessList.pager.currentPage) {
      this.props.actions.getBusinessListByCategory(catLink, subCatLink, page);
    }
}

  componentDidMount = () => {
    this.loadPage();
  };

  componentDidUpdate=(prevProps)=>{
    this.loadPage();

    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  callFromChild = (cat, subCat) => {
    let catLink;
    if (cat) catLink = cat.linkname;

    let subCatLink;
    if (subCat) subCatLink = subCat.linkname;

    const link = this.getLink(catLink, subCatLink);
    this.props.history.push(link);


    this.props.actions.changeBusinessList(initialState.businessList);
    this.props.actions.getBusinessListByCategory(catLink, subCatLink,1);
  };

  getLink = (categoryName, subCategoryName) => {
    let link = this.props.t("appurl.home");
    if (categoryName) {
      link = link.concat(categoryName);
    }

    if (subCategoryName) {
      link = link.concat("/" + subCategoryName + "/");
    }
    return link;
  };

  render() {
    const { t } = this.props;
    return (
      <Container fluid style={{ marginBottom: "30px" }}>
        <Ads />
        <Row>
          {/* lg="3" */}
          <Col>
            <BrowserView>
              <Row>
                <Col>
                  <CategoryMenuList callFromChild={this.callFromChild} />
                </Col>
              </Row>

              {/* 
            TODO:This place is for banner
            <Row>
              <Col>
                  <div className="centersuccess"><img alt="" src="/images/tick.png" width="100" height="400" /></div>
              </Col>
            </Row> */}
            </BrowserView>

            <MobileView>
              <Row>
                <Col>
                  <SearchForMobile
                    // msg={this.state.msg}
                    callFromChild={this.callFromChild}
                  />
                </Col>
              </Row>
            </MobileView>
          </Col>
          <Col lg="9">
            {getResultText(t, this.props.businessList.pageOfItems?this.props.businessList.pageOfItems.length:0)}
            {this.props.businessList.pageOfItems.length > 0 ? (
              this.props.businessList.pageOfItems.map((item, i) => (
                <div
                  key={i}
                  style={{ padding: "0px" }}
                  className="shadow-sm p-3 mb-5 bg-white rounded"
                >
                  <Jumbotron
                    style={{ margin: "0px", padding: "0px" }}
                    className={item.backcolor ? "ads" : "normal"}
                  >
                    {item.photo ? (
                      <Row>
                        <Col xl="3">
                          <CardImg
                            style={{ padding: "10px" }}
                            top
                            width="100px"
                            height="180px"
                            src={IMAGEURL_PREFIX + item.image}
                            alt="Card image cap"
                          />
                        </Col>

                        <Col>
                          <h1 className="display-6">
                            <span
                              className={item.namestyle}
                              style={{ marginLeft: "10px" }}
                            >
                              {item.name}
                            </span>
                          </h1>
                          <hr className="my-2" />
                          <p className="lead" style={{ marginLeft: "10px" }}>
                            {item.address}
                          </p>
                          <hr className="my-2" />

                          <BrowserView>
                            <p style={{ marginLeft: "10px" }}>{item.phone}</p>
                          </BrowserView>

                          <MobileView>
                            <p
                              onClick={() => {
                                window.open("tel:" + item.phone, "_blank");
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              {item.phone}
                            </p>
                          </MobileView>

                          <hr className="my-2" />
                          <p className="lead" style={{ marginLeft: "10px" }}>
                            {item.email}
                          </p>
                          <hr className="my-2" />
                          {/* <Link to={"/businessdetail/" + item._id}>
                        <Button color="primary">
                          {t("businesslist.detail")}
                        </Button>
                      </Link> */}
                        </Col>
                      </Row>
                    ) : (
                      <div>
                        <h1 className="display-6">
                          <span
                            className={item.namestyle}
                            style={{ marginLeft: "10px" }}
                          >
                            {item.name}
                          </span>
                        </h1>
                        <hr className="my-2" />
                        <p className="lead" style={{ marginLeft: "10px" }}>
                          {item.address}
                        </p>
                        <hr className="my-2" />

                        <BrowserView>
                          <p style={{ marginLeft: "10px" }}>{item.phone}</p>
                        </BrowserView>

                        <MobileView>
                          <Button
                            color="success"
                            onClick={() => {
                              window.open("tel:" + item.phone, "_blank");
                            }}
                            style={{ marginLeft: "10px" }}
                          >
                            {item.phone}
                          </Button>
                        </MobileView>
                        <hr className="my-2" />
                        <p className="lead" style={{ marginLeft: "10px" }}>
                          {item.email}
                        </p>
                        <hr className="my-2" />

                        {/* <Link to={"/businessdetail/" + item._id}>
                        <Button color="primary">
                          {t("businesslist.detail")}
                        </Button>
                      </Link> */}
                      </div>
                    )}
                  </Jumbotron>
                </div>
              ))
            ) : (
              <div style={{ marginBottom: "60px" }}>
                <div></div>
                <a href={t("appurl.addyourbusiness")}>
                  <Button color="warning">
                    {t("businesslist.addyourbusiness")}
                  </Button>
                </a>
              </div>
            )}

            
            <div className="pb-0 pt-1 centersuccess" style={{ marginBottom:"20px", padding: "0px" }}>
              {this.props.businessList.pager.pages &&
                this.props.businessList.pager.pages.length && (
                  <ul className="pagination">
                    {/* <li className={`page-item first-item ${pager.currentPage === 1 ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=1` }} className="page-link">First</Link>
                            </li> */}
                    <li
                      className={`page-item previous-item ${
                        this.props.businessList.pager.currentPage === 1
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <Link
                        to={{
                          search: `?page=${
                            this.props.businessList.pager.currentPage - 1
                          }`,
                        }}
                        className="page-link"
                      >
                        {"<<"}
                      </Link>
                    </li>
                    {this.props.businessList.pager.pages.map((page) => (
                      <li
                        key={page}
                        className={`page-item number-item ${
                          this.props.businessList.pager.currentPage === page
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link
                          to={{ search: `?page=${page}` }}
                          className="page-link"
                        >
                          {page}
                        </Link>
                      </li>
                    ))}
                    <li
                      className={`page-item next-item ${
                        this.props.businessList.pager.currentPage ===
                        this.props.businessList.pager.totalPages
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <Link
                        to={{
                          search: `?page=${
                            this.props.businessList.pager.currentPage + 1
                          }`,
                        }}
                        className="page-link"
                      >
                        {">>"}
                      </Link>
                    </li>
                    {/* <li className={`page-item last-item ${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                                <Link to={{ search: `?page=${pager.totalPages}` }} className="page-link">Last</Link>
                            </li> */}
                  </ul>
                )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
// }

function mapStateToProps(state) {
  return {
    businessList: state.getBusinessListReducer,
    currentCategory: state.changeCurrentCategoryReducer,
    currentSubCategory: state.changeCurrentSubCategoryReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getBusinessListByCategory: bindActionCreators(
        businessActions.getBusinessListByCategory,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategory: bindActionCreators(
        categoryActions.changeCurrentSubCategory,
        dispatch
      ),
      changeBusinessList: bindActionCreators(
        businessActions.getBusinessListSuccess,
        dispatch
      )
    },
  };
}

export default withTranslation("common")(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BusinessResultList))
);
