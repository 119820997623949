import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class Ads extends Component {
  render() {
    return (
      <div >
      <Carousel infiniteLoop useKeyboardArrows autoPlay showStatus={false} showThumbs={false}>
          <div>
              <img src="/images/slide_300.png" alt="slide" />
          </div>
          <div>
              <img src="/images/slide2_300.png" alt="slide2" />
          </div>
      </Carousel>
  </div>
    )
  }
}
