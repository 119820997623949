import * as actionTypes from "../actions/actionTypes";
import initialState from "./initialState";

export default function getBusinessListReducer(
  state = initialState.businessList,
  action
) {
  let newState;
  switch (action.type) {
    case actionTypes.GET_BUSINESSLIST_SUCCESS:
      return action.payload;
    case actionTypes.SAVE_BUSINESS_SUCCESS:
      const businessList = state.filter(
        (item) => item._id !== action.payload._id
      );
      businessList.push(action.payload);
      return businessList;

    case actionTypes.DELETE_BUSINESS_SUCCESS:
      const businessList1 = state.filter(
        (item) => item._id !== action.payload._id
      );
      businessList1.push(action.payload);
      return businessList1;

    case actionTypes.DELETEFULL_BUSINESS_SUCCESS:
      newState = state.filter((item) => item._id !== action.payload._id);
      return newState;
    default:
      return state;
  }
}
