export default {
    categories:[],
    currentCategory:{},
    subCategories:[],
    currentSubCategory:{},
    businessList:{pager:{pages:[]},pageOfItems:[]},
    currentBusiness:{},
    business: {
        catId: "",
        subCatId: "",
        name: "",
        address: "",
        phone: "",
        email: "",
        deleted:false,
        namestyle:"regular",
        backcolor:false,
        photo:false,
        approved:false,
        rankscore:1000
    },
    user:{user:{email:""},token:""},
    messageResponse:{},
    messageList:[]
  };
  