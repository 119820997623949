import React, { Component } from "react";

import { ListGroup, ListGroupItem, Badge, Container } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import * as businessActions from "../../../redux/actions/businessActions";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import initialState from "../../../redux/reducers/initialState";

class CategoryMenuList extends Component {
  async componentDidMount() {
    await this.props.actions.getCategories();
    const catLink=this.props.match.params.catName;
    const subCatLink=this.props.match.params.subCatName;

    let cat=this.props.categories.find(item=>item.linkname===catLink);
    if (cat) {
      this.props.actions.changeCurrentCategory(cat);
      // this.props.actions.changeCurrentSubCategory({});
      this.props.actions.changeCurrentSubCategoryList(cat.subCategories);

      let subCat=cat.subCategories.find(item=>item.linkname===subCatLink);
      if (subCat) 
      this.props.actions.changeCurrentSubCategory(subCat);
    }
    
  }

  getLink = (categoryName, subCategoryName) => {
    let link = this.props.t("appurl.home");
    if (categoryName) {
      link = link.concat(categoryName);
    }

    if (subCategoryName) {
      link = link.concat("/" + subCategoryName + "/");
    }
    return link;
  };

  selectCategory = (category) => {
    this.props.actions.changeCurrentCategory(category);
    this.props.actions.changeCurrentSubCategory({});
    let subCategories = category.subCategories;
    this.props.actions.changeCurrentSubCategoryList(subCategories);

    this.props.actions.changeBusinessList(initialState.businessList);
    const link=this.getLink(category.linkname,"");
    this.props.history.push(link+"?page=1");

  };

  selectSubCategory = (e, subCategory) => {
    e.preventDefault();
    this.props.actions.changeCurrentSubCategory(subCategory);

    const cat=this.props.currentCategory;
    this.props.actions.changeBusinessList(initialState.businessList);
    const link=this.getLink(cat.linkname,subCategory.linkname);
    this.props.history.push(link+"?page=1");
    // this.props.callFromChild(
    //   cat,
    //   subCategory
    // );
    e.stopPropagation();
  };
  //

  render() {
    const { t } = this.props;
    
    return (
      <Container>
        <h3>{t("categorymenulist.categories")}</h3>

        <h4>
          <Badge color="success">{this.props.currentCategory.name}</Badge>
        </h4>

        <h6>
          <Badge color="secondary">{this.props.currentSubCategory.name?this.props.currentSubCategory.name +"("+this.props.currentSubCategory.bcount+")":""}</Badge>
        </h6>

        <ListGroup style={{padding:"0px", marginTop: "10px" }}>
          {this.props.categories.map((item, i) => (
             <ListGroupItem
              style={{padding:"3px", backgroundColor: "#E2ECF1" }}
              key={i}
          
              onClick={() => this.selectCategory(item)}
            >
              {item.name}

              {this.props.currentCategory.name === item.name ? (
                <ListGroup>
                  {this.props.currentSubCategories.map((subItem, index) => (
                    <Link
                      key={subItem._id}
                      to={
                        t("appurl.home") +
                        item.linkname +
                        "/" +
                        subItem.linkname
                      }
                    >
                      <ListGroupItem style={{padding:"3px"}}
                        active={
                          this.props.currentSubCategory.name === subItem.name
                            ? true
                            : false
                        }
                        key={index}
                        onClick={(e) => {
                          this.selectSubCategory(e, subItem);
                        }}
                      >
                        {" "}
                        {subItem.name}{"("+subItem.bcount+")"}
                      </ListGroupItem>
                    </Link>
                  ))}
                </ListGroup>
              ) : null}
            </ListGroupItem>
           
          ))}
        </ListGroup>
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    categories: state.getCategoriesReducer,
    currentCategory: state.changeCurrentCategoryReducer,
    currentSubCategories: state.changeCurrentSubCategoryListReducer,
    currentSubCategory: state.changeCurrentSubCategoryReducer,
    prop: ownProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategoryList: bindActionCreators(
        categoryActions.changeCurrentSubCategoryList,
        dispatch
      ),
      changeCurrentSubCategory: bindActionCreators(
        categoryActions.changeCurrentSubCategory,
        dispatch
      ),
      getBusinessList: bindActionCreators(
        businessActions.getBusinessList,
        dispatch
      ),
      getBusinessListSuccess:bindActionCreators(
        businessActions.getBusinessList,
        dispatch
      ),
      changeBusinessList: bindActionCreators(
        businessActions.getBusinessListSuccess,
        dispatch
      )
    },
  };
}

export default withTranslation("common")(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryMenuList))
);
