import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState";

export default function changeCurrentBusinessReducer(state=initialState.business,action){
    switch (action.type) {
        case actionTypes.CHANGE_CURRENT_BUSINESS:
            return action.payload
        default:
            return state;
    }
}