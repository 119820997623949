import React, { Component } from "react";
import { Container } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import * as subCategoryActions from "../../../redux/actions/subCategoryActions";
import { withTranslation } from "react-i18next";

class SubCategoryForm extends Component {
  componentDidMount() {
    this.props.actions.getCategories();
  }


  required = (value) => {
    const { t } = this.props;
    const text = t("generalform.required");
    return value ? undefined : text;
  };

  email = (value) => {
    const { t } = this.props;
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? t("generalform.invalidemailaddress")
      : undefined;
  };

  renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
    <div>
      <label>{label}</label>
      <div>
        <input
          className="form-control"
          {...input}
          placeholder={label}
          type={type}
        />
        {touched &&
          ((error && (
            <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  renderTextArea = ({
    input,
    label,
    type,
    rows,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <textarea
          className="form-control"
          {...input}
          placeholder={label}
          rows={rows}
          type={type}
        />
        {touched &&
          ((error && (
            <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  renderSelectField = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
    disabled
  }) => (
    <div>
      <label>{label}</label>
      <div>
  <select {...input} disabled={disabled}>{children}</select>
        {touched && error && (
          <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
        )}
      </div>
    </div>
  );

  render() {
    const { t,history, handleSubmit,submitting } = this.props;
    let id = this.props.initialValues._id;
    let editmode = false;

    let disableCategorySelection;
    if(window.location.pathname==="/subcategoryform/new"){
      disableCategorySelection= false;
    }
    if (id) {
      editmode = true;
      disableCategorySelection = true;
    }

    return (

      <Container style={{ marginBottom: "50px" }}>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <h2 style={{ textAlign: "center" }}>{t("subcategoryform.title")}</h2>
            <form
              onSubmit={handleSubmit((values) => {
                console.log(JSON.stringify(values, null, 2));
                this.props.actions.saveSubCategory(values).then(() => {
                  history.push("/subcategorysavedsuccess");
                });
              })}
            >
              {editmode === true ? (
                <div className="form-group row">
                  <div className="col-sm-12">
                    <Field
                      name="createdDate"
                      component="input"
                      type="text"
                      disabled={disableCategorySelection}
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-group row">
                <div className="col-sm-6">
                  <Field
                    name="name"
                    component={this.renderField}
                    label={t("subcategoryform.name")}
                    type="text"
                    validate={[this.required]}
                  />
                </div>
                <div className="col-sm-6">
                  <Field
                    name="linkname"
                    component={this.renderField}
                    label={t("generalform.linkname")}
                    type="text"
                    validate={[this.required]}
                  />
                </div>
              </div>
              <div className="form-group row">
              <div className="col-sm-6">
              <Field
                      name="catId"
                      label={t("businessform.categoryname")}
                      component={this.renderSelectField}
                      onChange={this.selectCategory}
                      validate={[this.required]}
                      disabled={disableCategorySelection}
                    >
                      {/* <option /> */}
                      {this.props.categories.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                <div className="col-sm-6">
                  <Field
                    name="desc"
                    component={this.renderTextArea}
                    rows="4"
                    label={t("generalform.desc")}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-12">
                  {editmode === true ? (
                    <button
                      type="submit"
                      disabled={submitting}
                      className="btn btn-primary px-4 float-right"
                    >
                      {t("generalform.update")}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={submitting}
                      className="btn btn-primary px-4 float-right"
                    >
                      {t("generalform.save")}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
    );
  }
}

const mapStateToProps = (state, props) => {

    if(window.location.pathname==="/subcategoryform/new"){
    return{
      currentCategory: {},
      initialValues: {},
      categories: state.getCategoriesReducer,
    };
  }
else{
  return {
    currentCategory: state.changeCurrentCategoryReducer,
    initialValues: state.changeCurrentSubCategoryReducer,
    categories: state.getCategoriesReducer,
  };
}
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      // saveExam: bindActionCreators(saveExam, dispatch),
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      saveSubCategory: bindActionCreators(
        subCategoryActions.saveSubCategory,
        dispatch
      ),
    },
  };
};

export default withTranslation("common")(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: "SubCategoryForm",
      enableReinitialize: true,
    })(SubCategoryForm)
  )
);