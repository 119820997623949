import React, { Component } from "react";
import { Container } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as categoryActions from "../../../redux/actions/categoryActions";
import * as businessActions from "../../../redux/actions/businessActions";
import alertify from "alertifyjs";
import { saveBusiness } from "../../../redux/actions/businessActions";
import axios from "axios";
import * as APIURLS from "../../util/ApiUrls";
import initialState from "../../../redux/reducers/initialState";
import { withTranslation } from "react-i18next";

class BusinessForm extends Component {
  state = {
    selectedFile: null,
  };

  required = (value) => {
    const { t } = this.props;
    const text = t("generalform.required");
    return value ? undefined : text;
  };

  email = (value) => {
    const { t } = this.props;
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? t("generalform.invalidemailaddress")
      : undefined;
  };

  renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
    <div>
      <label>{label}</label>
      <div>
        <input
          className="form-control"
          {...input}
          placeholder={label}
          type={type}
        />
        {touched &&
          ((error && (
            <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  renderTextArea = ({
    input,
    label,
    type,
    rows,
    meta: { touched, error, warning },
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <textarea
          className="form-control"
          {...input}
          placeholder={label}
          rows={rows}
          type={type}
        />
        {touched &&
          ((error && (
            <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );

  renderSelectField = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <select {...input}>{children}</select>
        {touched && error && (
          <span style={{ color: "red", fontWeight: "bold" }}>{error}</span>
        )}
      </div>
    </div>
  );

  componentDidMount() {
    this.props.actions.getCategories();
    if (this.props.initialValues._id) {
      let selectedCat = this.props.categories.find(
        (item) => item._id === this.props.initialValues.catId
      );
      if (selectedCat)
        this.props.actions.changeCurrentSubCategoryList(
          selectedCat.subCategories
        );
    }
  }

  selectCategory = (event) => {
    let target = event.target;
    let index = target.selectedIndex;

    if (index !== 0) {
      let optionElement = target.children[index];
      let id = optionElement.getAttribute("value");

      let selectedCat = this.props.categories.find((item) => item._id === id);
      if (selectedCat)
        this.props.actions.changeCurrentSubCategoryList(
          selectedCat.subCategories
        );
    }
  };

  onChangeHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  saveBusiness = async (history, values) => {
    // console.log(JSON.stringify(values, null, 2));
    // this.props.actions.saveBusiness(values).then(() => {
    //   this.uploadFile();
    //   history.push("/businesssavesuccess");
    // });

    const image = this.state.selectedFile;
    const formData = new FormData();
    formData.append("catId", values.catId);
    formData.append("subCatId", values.subCatId);
    formData.append("name", values.name);
    formData.append("linkname",values.linkname);
    formData.append("address", values.address);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("approved", values.approved);
    formData.append("deleted", values.deleted);
    formData.append("namestyle", values.namestyle);
    formData.append("backcolor", values.backcolor);
    formData.append("photo", values.photo);
    formData.append("rankscore", values.rankscore);

    if (image) {
      formData.append("image", image);
    }

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    let url = "";
    if (values._id) {
      url = APIURLS.UPDATE_BUSINESS_URL + values._id;
      axios
        .put(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + userInfo.token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.props.actions.changeCurrentBusiness(response.data.business);
            history.push("/businesssavedsuccess");
          }
        })
        .catch((err) => {
          if (err.response) {
            alertify.error(err.response + ":" + err.message);
          } else if (err.request) {
            alertify.error("There is no response");
          } else {
            alertify.error("Unexpected error");
          }
        });
    } else {
      url = APIURLS.ADD_BUSINESS_URL;
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + userInfo.token,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.props.actions.changeCurrentBusiness(response.data.business);
            history.push("/businesssavedsuccess");
            this.props.actions.changeCurrentBusiness({});
          }
          if(response.status===400){
            throw Error(response.error)
          }
        })
        .catch((err) => {
          debugger;
          if (err.response) {
            alertify.error(err.response + ":" + err.message);
          } else if (err.request) {
            alertify.error("There is no response");
          } else {
            alertify.error("Unexpected error");
          }
        });
    }
  };

  render() {
    const { t, history, handleSubmit, submitting } = this.props;
    let editmode = false;
    let imageUrl = "";
    if (this.props.initialValues._id) {
      editmode = true;
      imageUrl = APIURLS.IMAGEURL_PREFIX + this.props.initialValues.image;
    }

    return (
      <Container style={{ marginBottom: "50px" }}>
        <div className="container py-5">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <h2 style={{ textAlign: "center" }}>{t("businessform.title")}</h2>
              <form
                onSubmit={handleSubmit((values) => {
                  //  console.log(JSON.stringify(values, null, 2));
                  this.saveBusiness(history, values);
                })}
              >
                {editmode === true ? (
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <Field
                        name="createdDate"
                        component="input"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                ) : null}

                <div className="form-group row">
                  <div className="col-sm-6">
                    <Field
                      name="name"
                      component={this.renderField}
                      label={t("businessform.name")}
                      type="text"
                      validate={[this.required]}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="linkname"
                      component={this.renderField}
                      label={t("businessform.linkname")}
                      type="text"
                      validate={[this.required]}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6">
                    <Field
                      name="phone"
                      component={this.renderField}
                      label={t("businessform.phone")}
                      type="text"
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="email"
                      component={this.renderField}
                      type="text"
                      label={t("businessform.email")}
                      validate={[this.email]}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-6">
                    <Field
                      name="catId"
                      label={t("businessform.categoryname")}
                      component={this.renderSelectField}
                      onChange={this.selectCategory}
                      validate={[this.required]}
                    >
                      <option />
                      {this.props.categories.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="subCatId"
                      label={t("businessform.subcategoryname")}
                      component={this.renderSelectField}
                      // validate={[this.required]}
                    >
                      <option /> 
                      {this.props.currentSubCategories.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-6">
                    <Field
                      name="address"
                      component={this.renderTextArea}
                      label={t("businessform.address")}
                      rows="3"
                      placeholder="Address"
                    />
                  </div>

                  <div className="col-sm-3">
                    <label htmlFor="approved">
                      {t("businessform.approved")}
                    </label>
                    <Field
                      name="approved"
                      component="input"
                      type="checkbox"
                      placeholder="App"
                      className="form-control"
                    />
                  </div>

                  <div className="col-sm-3">
                    <label htmlFor="deleted">
                      {t("businessform.hidden")}
                    </label>
                    <Field
                      name="deleted"
                      component="input"
                      type="checkbox"
                      placeholder="App"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="image">{t("businessform.image")}</label>
                  <div className="col-sm-6">
                    <input
                      type="file"
                      name="image"
                      accept="image/png, image/jpeg,image/jpg"
                      onChange={this.onChangeHandler}
                      className="form-control"
                    />
                  </div>
                  {editmode === true ? (
                    <div className="col-sm-6">
                      <label htmlFor="currentimage">
                        {t("businessform.currentimage")}
                      </label>
                      <img
                        alt={imageUrl}
                        src={imageUrl}
                        width="100"
                        height="100"
                      />
                    </div>
                  ) : null}
                </div>
                <br />
                <hr className="my-2" />
                <h4 className="centersuccess">
                  {t("businessform.adsoptions")}
                </h4>

                <div className="form-group row">
                  <div className="col-sm-6">
                    <Field
                      name="namestyle"
                      label={t("businessform.namestyle")}
                      component={this.renderSelectField}
                    >
                      <option value="regular">Regular</option>
                      <option value="bold">Bold</option>
                      <option value="blink">Blink</option>
                    </Field>
                  </div>

                  <div className="col-sm-6">
                    <label>{t("businessform.rankscore")}</label>
                    <Field
                      name="rankscore"
                      component="input"
                      type="number"
                      className="form-control"
                      min="0"
                      max="10000"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-6">
                    <label>{t("businessform.imageisvisible")}</label>
                    <Field
                      name="photo"
                      component="input"
                      type="checkbox"
                      className="form-control"
                    />
                  </div>

                  <div className="col-sm-6">
                    <label>{t("businessform.backgroundcolor")}</label>
                    <Field
                      name="backcolor"
                      component="input"
                      type="checkbox"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    {editmode === true ? (
                      <button
                        type="submit"
                        disabled={submitting}
                        className="btn btn-primary px-4 float-right"
                      >
                        {t("generalform.update")}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={submitting}
                        className="btn btn-primary px-4 float-right"
                      >
                        {t("generalform.save")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  if (window.location.pathname === "/businessform/new") {
    return {
      initialValues: initialState.business,
      categories: state.getCategoriesReducer,
      currentCategory: {},
      currentSubCategories: state.changeCurrentSubCategoryListReducer,
    };
  } else {
    return {
      initialValues: state.changeCurrentBusinessReducer,
      categories: state.getCategoriesReducer,
      currentCategory: state.changeCurrentCategoryReducer,
      currentSubCategories: state.changeCurrentSubCategoryListReducer,
    };
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      saveBusiness: bindActionCreators(saveBusiness, dispatch),
      getCategories: bindActionCreators(
        categoryActions.getCategories,
        dispatch
      ),
      changeCurrentCategory: bindActionCreators(
        categoryActions.changeCurrentCategory,
        dispatch
      ),
      changeCurrentSubCategoryList: bindActionCreators(
        categoryActions.changeCurrentSubCategoryList,
        dispatch
      ),
      changeCurrentBusiness: bindActionCreators(
        businessActions.changeCurrentBusiness,
        dispatch
      ),
    },
  };
};

export default withTranslation("common")(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: "BusinessForm",
      enableReinitialize: true,
    })(BusinessForm)
  )
);
