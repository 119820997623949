import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/reducers/configureStore";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import common_tr from "./translations/tr/common.json"
import common_en from "./translations/en/common.json"
import common_es from "./translations/es/common.json"

import "bootstrap/dist/css/bootstrap.min.css";
import "alertifyjs/build/css/alertify.min.css";
import "./App.css";

i18next.init({
    interpolation: { escapeValue: true },
    transSupportBasicHtmlNodes:true,
    transKeepBasicHtmlNodesFor:
['br', 'strong', 'i', 'p'],  // React already does escaping
    lng: 'tr',                              // language to use
    resources: {
        tr: {
            common: common_tr               // 'common' is our custom namespace
        },
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        es: {
            common: common_es              // 'common' is our custom namespace
        }
    },
});

const store = configureStore();
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
        </I18nextProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
