import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class MobileFooter extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="container-fluid fixed-bottom bg-dark p-2">
        <div className="row">
          <div className="col text-center border-right">
            <Link to="/">
              <img
                border="0"
                alt="search"
                src="/icons/search.svg"
                width="40"
                height="40"
              />
            </Link>
          </div>
          <div className="col text-center border-right">
            <a href={t("appurl.addyourbusiness")}>
              <img
                border="0"
                alt="addbook"
                src="/icons/addbook.svg"
                width="40"
                height="40"
              />
            </a>
          </div>

          <div className="col text-center">
            <Link to={t("appurl.contact")}>
              <img
                border="0"
                alt="email"
                src="/icons/mail.svg"
                width="40"
                height="40"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(MobileFooter);
